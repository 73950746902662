import * as styles from './AddCards.module.scss'

import React, { useState, useEffect, useCallback, useRef } from 'react'
import classNames from 'classnames'
import { uniq } from 'lodash'
import { useRecoilValue } from 'recoil'

import * as scryfall from 'utils/scryfall'
import { useDebouncedState } from 'utils/useDebounce'

import { selectedGroupState } from './data/recoilState'
import { ScratchpadState } from './data/state'

interface Props {
  state: ScratchpadState
}

const AddCards: React.FC<Props> = (props) => {
  const { state } = props

  const { addCard } = state

  const selectedGroup = useRecoilValue(selectedGroupState)

  const abortController = useRef<AbortController | null>(null)
  const cancel = useCallback(() => {
    abortController.current?.abort()
    abortController.current = null
  }, [])

  const resultsFor = useCallback(
    async (query: string) => {
      cancel()

      abortController.current = new AbortController()

      const cards = await scryfall
        .search(query, {
          includeExtras: true,
          order: 'edhrec',
          abortSignal: abortController.current.signal,
        })
        .catch(() => {
          return [] as scryfall.Card[]
        })

      return uniq(scryfall.cardNames(cards)).slice(0, 20)
    },
    [cancel],
  )

  const shouldDebounce = (value: string) => {
    return value.length > 0
  }

  const searchInput = useRef<HTMLInputElement | null>(null)
  const [query, setQuery, debouncedQuery] = useDebouncedState<string>(
    '',
    150,
    shouldDebounce,
  )

  const [selectedResultIndex, setSelectedResultIndex] = useState(0)
  const [searchResults, setSearchResults] = useState<string[] | null>(null)

  const [focused, setFocused] = useState(false)
  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!focused) {
      return
    }

    const onBlur = (event: FocusEvent) => {
      if (!containerRef.current?.contains(event.target as Node)) {
        setFocused(false)
      }
    }

    document.addEventListener('focusin', onBlur)

    return () => {
      document.removeEventListener('focusin', onBlur)
    }
  }, [focused])

  useEffect(() => {
    if (debouncedQuery.length < 3) {
      setSearchResults(null)
    } else {
      resultsFor(debouncedQuery).then((results) => setSearchResults(results))
    }
  }, [debouncedQuery, resultsFor])

  useEffect(() => {
    setSelectedResultIndex(0)
  }, [searchResults])

  const onSelectResult = useCallback(
    (result: string) => {
      cancel && cancel()
      setQuery('')
      if (selectedGroup != null) {
        addCard(result, selectedGroup)
      }
    },
    [cancel, setQuery, addCard, selectedGroup],
  )

  const onKeyUp = useCallback(
    (event: React.KeyboardEvent) => {
      if (searchResults?.length == null || searchResults?.length === 0) {
        return
      }
      if (event.key === 'ArrowUp') {
        setSelectedResultIndex((value) => Math.max(value - 1, 0))
      } else if (event.key === 'ArrowDown') {
        setSelectedResultIndex((value) =>
          Math.min(value + 1, searchResults?.length - 1),
        )
      } else if (event.key === 'Escape') {
        searchInput.current?.blur()
      }
    },
    [searchResults?.length],
  )

  const onInputKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (
        searchResults?.length != null &&
        searchResults?.length !== 0 &&
        event.key === 'Enter'
      ) {
        onSelectResult(searchResults[selectedResultIndex])
      }
    },
    [onSelectResult, searchResults, selectedResultIndex],
  )

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(event.currentTarget.value)
    },
    [setQuery],
  )

  return (
    <div className={styles.container} ref={containerRef}>
      <input
        type="text"
        className={styles.input}
        placeholder="Add Card"
        autoComplete="off"
        autoCorrect="false"
        spellCheck="false"
        ref={searchInput}
        value={query}
        onChange={onChange}
        onKeyPress={onInputKeyPress}
        onKeyUp={onKeyUp}
        onFocus={() => setFocused(true)}
      />

      {focused && searchResults != null && (
        <div className={styles.results}>
          {searchResults.length > 0 ? (
            <>
              {searchResults.map((result, index) => (
                <div
                  key={`${result}-${index}`}
                  className={classNames(styles.result, {
                    [styles.selected]: index === selectedResultIndex,
                  })}
                  onClick={() => onSelectResult(result)}
                  onKeyPress={onInputKeyPress}
                  onMouseEnter={() => setSelectedResultIndex(index)}
                  onFocus={() => setSelectedResultIndex(index)}
                  tabIndex={0}
                >
                  <div className={styles.title}>{result}</div>
                </div>
              ))}
            </>
          ) : (
            <div className={styles.noResults}>No cards found</div>
          )}
        </div>
      )}
    </div>
  )
}

export default AddCards
